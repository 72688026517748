code {
	background: #f4f4f4;
	border: 1px solid #ddd;
	border-left: 3px solid #f36d33;
	color: #666;
	page-break-inside: avoid;
	font-family: monospace;
	font-size: 15px;
	line-height: 1.6;
	margin-bottom: 1.6em;
	max-width: 100%;
	padding: 1em 1.5em;
	display: block;
	word-wrap: break-word !important;
	white-space: pre-line !important;
}

pre {
	white-space: pre !important;
}

.block-container{
	display: flex;
	vertical-align: middle;
}

.block-container label {
	font-weight: 600;
	line-height: 3.0;
}

.block-container .ui.button {
	border: 1px solid #888;
	padding: 5px 20px;
	margin: 10px;
	color: #888;
	width: 88px;
	height: 36px;
}

.block-container .block {
	border: 1px solid #888;
	background-color: rgb(189, 189, 189);
	padding: 5px 20px;
	margin: 10px;
	color: #888;
}

.block-container .block.active {
	border: 1px solid #666;
	background-color: rgb(0, 100, 128);
	color: #fff;
	font-weight: 600;
}

.block-container .block.active.red {
	background-color: rgb(128, 0, 0);
}

.control {
	font-size: 1.5em;
	font-weight: 700;
}

.control.gs {
	color:rgb(255, 0, 0);
}
.control.rs {
	color:rgb(50, 50, 150);
}

.control.eot {
	color:rgb(0, 100, 0);
}

.control.cr {
	color:rgb(97, 0, 100);
}

.control.lf {
	color:rgb(97, 0, 100);
}

.control.fs {
	color:rgb(9, 215, 9);
}