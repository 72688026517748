.partTypeSelector-container {
	position: relative;
}

/*.partTypeSelector-container div.icon:has(+ .partTypeSelector) { (no support in firefox yet) */
	.partTypeSelector-container div.icon {
	position: absolute;
	top: 8px;
	left: 10px;
	z-index: 999;
}

.ui.selection.dropdown.partTypeSelector {
	padding-top: 0 !important;
}

.ui.selection.dropdown.partTypeSelector input.search {
	padding-left: 3.5em;
}

.ui.selection.dropdown.partTypeSelector .text {
	top: 0.75em;
	left: 2.5em;
}

.ui.selection.dropdown.partTypeSelector .text svg {
	margin-top: -1px;
	margin-right: 10px;
}

.partTypeSelector.ui.active.search.dropdown input.search:focus+.text {
	color: rgba(0,0,0,1)!important;
}

.partTypeSelector.ui.default.dropdown:not(.button)>.text, .partTypeSelector.ui.dropdown:not(.button)>.default.text {
	color: rgba(0,0,0,0.87);
}

.partTypeSelectorTreeView .MuiTreeItem-group {
	margin-left: 10px !important;
}
.partTypeSelectorTreeView li.MuiTreeItem-root {
	margin-left: 0px;
}
.partTypeSelectorTreeView .MuiTreeItem-content {
	padding-left: 0;
}
.partTypeSelectorTreeView .MuiTreeItem-group .MuiTreeItem-content {
	padding-left: 0 !important;
}

.partTypeSelector-container svg.parttype {
	color: #bbb;
	margin-right: 5px;
	vertical-align: baseline;
}

.partTypeSelector-container div.parttype:has(svg) {
	color: #bbb;
	margin-right: 5px;
}

.partTypeSelector-container div.parttype>svg {
	max-width: 24px;
	max-height: 24px;
	vertical-align: baseline;
}