.error-modal {
	min-height: 400px;
}

.error-modal .content {
}

.error-modal .ui.medium.image {
	width: 200px;
}

.error-modal .description {
	width: 100% !important;
}

.error-modal .description footer {
	margin-top: auto;
}

.error-modal .description hr {
	border: none;
	height: 4px;
	opacity: 1.0;
	border-radius: 4px;
	color: #c00;
	background-color: #c00;
	margin-top: 50px;
}

.error-modal .description p {
	color: #888;
	font-size: 0.9rem;
	font-weight: 300;
}

.error-modal .message div:nth-of-type(1) {
	font-size: 1.3rem;
	font-weight: 500;
	color: #333;
}

.error-modal .message {
	font-size: 1.1rem;
	font-weight: 500;
	color: #333;
}