.version-banner {
	position: fixed;
	left: 25%;
	top: -100px;
	z-index: 999;
	width: 50vw;
	height: 40px;
	line-height: 2em;
	background-color: #0D1117;
	color: #e6edf3;
	padding: 2px 10px;
	border: 1px dotted #30363d;
	font-weight: 400;
	box-shadow: 0 2px 2px 0 rgb(34 36 38 / 15%);
	margin: 0 auto;
	vertical-align: middle;
	border-radius: 4px;
	transition-property: top;
	transition-delay: 0s;
	transition-duration: 0.5s;
	transition-timing-function: cubic-bezier(.47,1.64,.41,.8);
}

.version-banner.open {
	top: 5px;
}

.version-banner .button {
	height: 30px;
	padding: 0 0.75em;
	margin: 0 5px;
	border-radius: 4px;
	border: 1px solid #0366d6;
}

.version-banner .ui.button.primary {
	background-color: #0366d6;
}

.ui.modal.release-notes{
	background-color: transparent;
}

.ui.modal.release-notes .header, .ui.modal.release-notes .description, .ui.modal.release-notes .content, .ui.modal.release-notes .actions  {
	background-color: #0D1117;
	color: #e6edf3;
}

.ui.modal.release-notes .header {
	border-bottom: 1px solid #30363d;
}

.ui.modal.release-notes .actions {
	border-top: 1px solid #30363d;
}

.ui.modal.release-notes .actions .ui.primary.button  {
	background-color: #238636;
}

.ui.modal.release-notes .description p {
	color: #0366d6;
	font-size: 1.2em;
	font-weight: 700;
}

.ui.modal.release-notes .content {
	background-color: #0D1117;
	color: #e6edf3;
}

.ui.modal.release-notes .content a {
	color: #2f81f7;
}

.ui.modal.release-notes .content ul {
	list-style: none;
	margin-top: 5px;
}

.ui.modal.release-notes .content li {
	color: #7d8590;
	padding: 2px;
}

.ui.modal.release-notes .content li::before {
	content: "\2022";
  color: #0366d6;
  font-weight: 700;
  display: inline-block;
  width: 1em;
  margin-left: -1.2em;
}

.ui.modal.release-notes .content h2 {
	display: inline-block;
	font-size: 1.2em;
	line-height: 1.2em;
	font-weight: 700;
	margin-bottom: 0;
	padding-bottom: 0;
}

.ui.modal.release-notes .content .bullet {
	font-size: 1.25em;
	font-weight: 700;
	margin-right: 10px;
}