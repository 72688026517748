.bulkScanModal .anim-box {
	position: relative;
	width: 100%;
	height: 100%;
	padding: 0;
	transition: transform .6s ease-out;
	animation-play-state: running;
	background-color: #000;
	overflow: hidden;
}

.bulkScanModal .anim-item {
	display: inline-block;
	background-color: #fff;
	height: 100%;
}

.bulkScanModal .anim-item-sm {
	width: 2px;
	margin-right: 3px;
}

.bulkScanModal .anim-item-md {
	width: 3px;
	margin-right: 2px;
}

.bulkScanModal .anim-item-lg {
	width: 5px;
	margin-right: 5px;
}

.bulkScanModal .scanner {
	width: 95%;
	margin: 0 auto;
	margin-top: -1px;
	height: 1px;
	background-color: red;
	opacity: 0.7;
	position: relative;
	box-shadow: 0px 0px 2px 3px rgba(170, 11, 23, 0.4);
	top: 50%;
	animation-name: scan;
	animation-duration: 2s;
	animation-timing-function: linear;
	animation-iteration-count: infinite;
	animation-play-state: paused;
}

.bulkScanModal .scanner.animated {
	animation-play-state: running;
}

@keyframes scan {
	0% {
			box-shadow: 0px 0px 4px 5px rgba(170, 11, 23, 0.49);
			top: 50%;
	}

	25% {
			box-shadow: 0px 6px 4px 5px rgba(170, 11, 23, 0.49);
			top: 5px;
	}

	75% {
			box-shadow: 0px -6px 4px 5px rgba(170, 11, 23, 0.49);
			top: 98%;
	}
}

.bulkScanModal .lastScannedPart {
	transition-duration: 1s;
	transition-property: background-color;
	background-color: #dff1fe;
}

.bulkScanModal .lastScannedPart.scannedPartAnimation {
	background-color: #2185d0;
}

.bulkScanModal .exists {
	background-color: #f3b4b4;
}