.barcodescan {
	display: inline-block;
	cursor: pointer;
	background-color: #fff;
	width: 200px;
	height: 60px;
}

.barcodescan .anim-box {
	position: relative;
	width: 100%;
	height: 100%;
	padding: 0;
	transition: transform .6s ease-out;
	animation-play-state: running;
	background-color: #000;
	overflow: hidden;
}

/* barcode bars for manual drawing */
.barcodescan .anim-item {
	display: inline-block;
	background-color: #fff;
	height: 100%;
}

.barcodescan .anim-item-sm {
	width: 2px;
	margin-right: 3px;
}

.barcodescan .anim-item-md {
	width: 3px;
	margin-right: 2px;
}

.barcodescan .anim-item-lg {
	width: 5px;
	margin-right: 5px;
}

.barcodescan .anim-box:hover > .scanner {
	animation-play-state: running;
}

.barcodescan .anim-box > .scanner {
	width: 95%;
	margin: 0 auto;
	margin-top: -1px;
	height: 1px;
	background-color: red;
	opacity: 0.7;
	position: relative;
	box-shadow: 0px 0px 2px 3px rgba(170, 11, 23, 0.4);
	top: 50%;
	animation-name: scan;
	animation-duration: 1.5s;
	animation-timing-function: linear;
	animation-iteration-count: infinite;
	animation-play-state: paused;
}

@keyframes scan {
	0% {
			box-shadow: 0px 0px 4px 5px rgba(170, 11, 23, 0.49);
			top: 50%;
	}

	25% {
			box-shadow: 0px 6px 4px 5px rgba(170, 11, 23, 0.49);
			top: 5px;
	}

	75% {
			box-shadow: 0px -6px 4px 5px rgba(170, 11, 23, 0.49);
			top: 98%;
	}
}