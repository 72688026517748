.large {
	font-weight: 600;
	font-size: 2em;
}

.ui.segment.thicker,
.ui.red.segment.thicker,
.ui.blue.segment.thicker,
.ui.black.segment.thicker,
.ui.green.segment.thicker,
.ui.orange.segment.thicker,
.ui.purple.segment.thicker,
.ui.yellow.segment.thicker,
.ui.pink.segment.thicker,
.ui.grey.segment.thicker,
.ui.voilet.segment.thicker {
	border-top-width: 5px !important;
}

.statisticsContainer {
	padding: 0.8em !important;
}

.statisticsContainer .ui.statistic {
	margin: 0px auto 20px auto !important;
}

.statisticsContainer .ui.tiny.statistic>.value, .statisticsContainer .ui.tiny.statistics .statistic>.value {
	font-size: 1.5em !important;
}

.statisticsContainer .ui.statistic>.label {
	font-size: 0.9em !important;
}

.statisticsContainer .inventorymessage, .produce-summary{
	min-height: 32px;
}

.statisticsContainer .inventorymessage, .produce-summary .inventorymessage {
	font-size: 0.9em;
	color: #ccc;
}

.statisticsContainer .inventorymessage b, .produce-summary .inventorymessage b {
	color: #fff;
}

.statisticsContainer .inventorymessage i, .produce-summary .inventorymessage i {
	font-style: normal;
	font-weight: 200;
	color: rgb(248, 234, 173);
}

.projectinfo a {
	font-size: 80%;
	font-weight: 400;
}

.projectinfo .large {
	font-weight: 600;
	font-size: 2em;
}

.projectinfo label {
	font-size: .78571429rem;
	font-weight: 500 !important;
	color: rgba(0,0,0,.6);
}

.buttons {
}

.buttons .ui.button {
	margin-right: 10px;
}

.buttons.small .ui.button {
	padding: 5px 15px;
	background-color: #ccc;
}

.buttons.small .ui.button:hover {
	background-color: #bbb;
}

.buttons.small .menu .item {
	padding: .6rem 0.9rem!important;	
	font-size: 1em;
}

.ui.form.bom .ui.tabular.menu > .item {
	max-width: 220px;
}

/* allow for text ellipsis in tabs */
.ui.form.bom .ui.tabular.menu > .item > div {
	max-width: 220px;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.ui.form.bom .ui.tabular.menu > .item.smaller {
	max-width: 125px;
	padding: 0.98em 0.3em;
	font-size: 0.9em;
}

/* allow for text ellipsis in tabs */
.ui.form.bom .ui.tabular.menu > .item.smaller > div {
	max-width: 125px;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.ui.form.bom .ui.tabular.menu > .item.active > .label {
	background-color: rgb(33, 133, 208, 1);
}

.ui.form.bom .ui.tabular.menu > .item > .label {
	background-color: rgb(0,0,0, 0.15);
}

.ui.tab {
	padding: 0;
	min-height: 800px;
}

.ui.table.bom{
	font-size: 0.9em;
	width: 2000px;
	max-width: 2000px;
}

.ui.form.bom .buttons .ui.mini.button {
	line-height: 1.3em;
}

.producePcbModal .actions .ui.button {
	line-height: 1.2em;
}

.produce-summary {
	width: 100%;
	padding: 4px;
	margin: 0;
	text-align: center;
	background-color: rgb(0,0,0,1);
}

input[type=checkbox] {
	cursor: pointer;
}

.ui.table.bom {
	margin-top: 0;
}

table.bom th {
	
}

table.bom td {
	vertical-align: middle;
}

td.overflow>div {
	display: inline-block;/* or inline-block */
  text-overflow: ellipsis;
  word-wrap: normal;
  overflow: hidden;
  max-height: 3.2em !important;
  line-height: 1.6em !important;
	display: -webkit-box; 
	-webkit-line-clamp: 2; 
	-webkit-box-orient: vertical;
}

td.overflow span {
	
}

.scroll-container{
	display: block;
	overflow-x: scroll;
}

b.outofstock {
	color:#c00;
}

div.outofstock {
	border: 1px dashed #c00;
}

div.outofstock>input {
	color: #c00 !important;
	font-weight: 700;
}

#activePartName {
	display: 'inline-block';
	text-align: center;
	transition-property: opacity;
  transition-duration: 0.25s;
	opacity: 0;
	border: 1px solid #024d86;
	border-radius: 4px;
	padding: 2px 20px;
	background-color: #2185d0;
	margin: 5px auto;
	color: #fff;
	font-size: 1.1em;
	font-weight: 600;
	height: 30px;
}

.textvalignmiddle {
	line-height: 20px;
}

.textvalignmiddle > span {
	display: inline-block;
	vertical-align: middle;
	line-height: 1em; /*set line height back to normal*/
}