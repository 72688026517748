.help .mdblock {
	font-family: 'Source Code Pro', monospace;
	color: #000;
	font-size: 0.7em;
	font-weight: 500;
	padding: 10px 20px;
	background-color: #ddd;
	border: 1px dashed #aaa;
	margin: 20px 10px;
}

.help .definitions {
	padding: 20px;
	margin: 10px 10px;
	border: 1px solid #aaa;
}

.help .definition {
	margin-bottom: 20px;
}

.help .definition.optional span:after {
	content: 'optional';
	margin: 0 10px 0 0px;
	font-style: italic;
	font-weight: 500;
}

.help .definition span:nth-child(1) {
	font-weight: 700;
}

.help table.excel {
	font-size: 0.8em;
	margin-top: 20px;
	margin-bottom: 50px;
}

.help table.excel tr:nth-child(1) {
	border: 1px solid #000;
	padding: 0 5px;
	text-align: center;
	background-color: #ddd !important;
}

.help table.excel tr:nth-child(1) td {
	border: 1px solid #000;
	background-color: #ddd !important;
	font-weight: 800;
}

.help table.excel tr:nth-child(2) td {
	font-weight: 800;
}

.help table.excel td:nth-child(1) {
	border: 1px solid #000;
	font-weight: 800;
	padding: 0 10px;
	text-align: center;
	background-color: #ddd;
}

.help table.excel td {
	font-size: 0.9em;
	border: 1px dotted #aaa;
	padding: 2px 6px;
	background-color: #fff;
}

.help table.excel tr:last-child td {
	text-align: left;
}

.help table.excel tr:last-child td span.active {
	background-color: #fff;
	margin-left: 20px;
	right: 0;
	padding: 4px 15px;
	border-radius: 0 0 8px 8px;
	border: 1px solid #000;
	border-top: 0;
	top: 1px;
}

.help table.excel tr:last-child td span {
	background-color: #dadada;
	border-right: 1px solid #aaa;
	padding: 1px 15px;
	position: relative;
	top: 1px;
}

.help .helpcontainer {
  margin: auto;
  width: 90%;
}

.help .helpcontainer ul {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.help .helpcontainer li {
  list-style: none;
  display: inline-block;
  border: 1px solid #ccc;
  border-top: 5px solid rgb(0, 115, 204);
  max-width: 200px;
  min-width: 150px;
  min-height: 200px;
  margin: 10px;
  text-align: center;
  color: #666;
  font-size: 0.9em;
  padding: 10px;
	cursor: pointer;
}

.help .helpcontainer li:HOVER{
  background-color: #f6f6f6;
  border-top: 5px solid #DF1461;
}

.help .helpcontainer li.disabled:HOVER{
  background-color: #f6f6f6;
  border-top: 5px solid #999999;
}

.help .helpcontainer li h1 {
  display: block;
  color: #000;
  font-weight: 800;
  font-size: 1.1em;
  min-height: 50px;
  padding: 10px;
}

.help .helpcontainer li h1 span {
	text-decoration: underline;
}

.help div.helpvideo {
  color: #666;
  font-size: 0.9em;
  margin-bottom: 10px;
  padding: 20px;
  background-color: #f6f6f6;
}

.help .helpvideo img {
  display: block;
  border: 1px dotted #666;
  padding: 5px;
  width: 700px;
  height: auto;
}

.help img.helpvideo {
  border: 1px dotted #666;
  padding: 5px;
  width: 700px;
  height: auto;
}

.help .imagegroup {
	display: flex;
}

.help .imagegroup div {
	margin: 10px 50px;
}

.help .imagegroup img {
	display: block;
  border: 1px dotted #666;
  padding: 5px;
}

.help textarea {
	width: 75%;
	height: 200px;
}

.helpimage {
	padding: 10px;
	font-size: 0.8em;
	font-style: italic;
}

.helpimage img {
	border: 1px dashed #eee;
	padding: 10px;
	max-width: 90%;
	height: auto;
	display: block;
}

.helpimage.large img {
	max-width: 90%;
}

.help .bullet.highlight > .helpimage img {
	border: 1px dashed #aaa;
}

.help code {
	background: #f4f4f4;
	border: 1px solid #ddd;
	border-left: 3px solid #f36d33;
	color: #666;
	page-break-inside: avoid;
	font-family: monospace;
	font-size: 15px;
	line-height: 1.6;
	margin-bottom: 1.6em;
	max-width: 100%;
	padding: 1em 1.5em;
	display: block;
	word-wrap: break-word !important;
	white-space: pre-line !important;
}

.help pre {
	white-space: pre !important;
}

.help p > b, li > b, .bullet > b {
	font-weight: 600;
	color: rgb(0, 115, 204);
}

.help li {
	padding: 10px 0;
	margin-left: 30px;
}

.help .segment {
	padding: 40px;
}

.help .bullet {
	border-left: 2px solid #3340f3;
	padding: 20px;
	margin-left: 30px;
	margin-top: 30px;
	margin-bottom: 50px;
	font-weight: 500;
}

.help .bullet.highlight {
	background-color: #eee;
}

.help hr {
	margin-top: 75px;
	margin-bottom: 30px;
}