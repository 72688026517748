#datasheets .icon-container { 
	display: flex;
}

#datasheets .icon-container.small svg { 
	height: 0.9em;
	color: gray;
}

#datasheets .icon-container.small { 
	font-size: 1em;
}

#datasheets td .icon-container div {
	margin-left: auto;
	margin-right: 10px;
}

#datasheets .ui.dropdown .menu>.item  {
	display: flex;
	flex-direction: column-reverse;
	position: relative;
}

#datasheets .ui.dropdown .menu>.item>.icon,.ui.dropdown .menu>.item>.MuiSvgIcon-root {
	position: absolute;
	top: 14px;
	left: 2px;
}

#datasheets .ui.dropdown .menu>.item>.MuiSvgIcon-root {
	color: #ccc;
}

#datasheets .ui.dropdown .menu>.item>.text {
	display:flex;
	justify-content: start;
	margin: 0;
	padding-left: 10px;
}

#datasheets .ui.dropdown .menu>.item>.description {
	display:flex;
	justify-content: start;
	flex: 1;
	font-size: 0.8em;
	margin: 0;
	padding-left: 10px;
	float: none;
	font-weight: normal;
}